.language-selector-popover {
  .ant-popover-inner-content {
    p {
      margin-bottom: 20px;
      &::before {
        content: '*';
        color: red;
        margin-right: 5px;
      }
    }
    svg {
      position: relative;
      top: 3px;
      right: -5px;
    }
  }
  .tts-info {
    margin: 20px 0;
    svg {
      right: 0px;
      margin-right: 5px;
    }
  }
}
