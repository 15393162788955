.ruleGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  background: none;
  border: none;
}

.ruleGroup .ruleGroup-body {
  background: rgba(200, 200, 200, 0.2);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 7px;
}

.ruleGroup .ruleGroup-body:empty {
  display: none;
}

.ruleGroup .ruleGroup-header,
.ruleGroup .rule {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.ruleGroup-header-close {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.ruleGroup-header > .ruleGroup-header-close {
  position: relative;
  left: 8px;
}

.ruleGroup .rule .rule-value .rule-value-list-item:not(:first-of-type) {
  margin-left: 0.5rem;
}

[data-inlinecombinators='disabled'] .dndOver.rule,
[data-inlinecombinators='disabled'] .dndOver.ruleGroup-header {
  border-bottom-width: 2px;
  border-bottom-style: dashed;
  border-bottom-color: rebeccapurple;
  padding-bottom: 0.5rem;
}

[data-inlinecombinators='disabled'] .dndOver.rule.dndCopy,
[data-inlinecombinators='disabled'] .dndOver.ruleGroup-header.dndCopy {
  border-bottom-color: #669933;
}

[data-inlinecombinators='enabled'] .dndOver.rule:last-child,
[data-inlinecombinators='enabled'] .dndOver.ruleGroup-header,
[data-inlinecombinators='enabled'] .dndOver.rule + .betweenRules,
[data-inlinecombinators='enabled'] .dndOver.betweenRules {
  border-bottom-width: 2px;
  border-bottom-style: dashed;
  border-bottom-color: rebeccapurple;
  padding-bottom: 0.5rem;
}

[data-inlinecombinators='enabled'] .dndOver.rule:last-child.dndCopy,
[data-inlinecombinators='enabled'] .dndOver.ruleGroup-header.dndCopy,
[data-inlinecombinators='enabled'] .dndOver.rule + .betweenRules.dndCopy,
[data-inlinecombinators='enabled'] .dndOver.betweenRules.dndCopy {
  border-bottom-color: #669933;
}

.ruleGroup.dndDragging,
.rule.dndDragging {
  opacity: 0.5;
}

.ruleGroup .queryBuilder-dragHandle,
.rule .queryBuilder-dragHandle {
  cursor: move;
}

[data-dnd='disabled'] .queryBuilder-dragHandle {
  display: none;
}

.queryBuilder-branches .ruleGroup-body {
  margin-left: calc(2 * 0.5rem);
}

.queryBuilder-branches .rule,
.queryBuilder-branches .ruleGroup .ruleGroup {
  position: relative;
}

.queryBuilder-branches .rule::before,
.queryBuilder-branches .rule::after,
.queryBuilder-branches .ruleGroup .ruleGroup::before,
.queryBuilder-branches .ruleGroup .ruleGroup::after {
  content: '';
  width: 2rem;
  left: calc(-0.5rem - 1px);
  border-color: #8081a2;
  border-style: solid;
  border-radius: 0;
  position: absolute;
}

.queryBuilder-branches .rule::before,
.queryBuilder-branches .ruleGroup .ruleGroup::before {
  top: -1rem;
  height: calc(50% + 1rem);
  border-width: 0 0 1px 1px;
}

.queryBuilder-branches .rule:last-child::before,
.queryBuilder-branches .ruleGroup .ruleGroup:last-child::before {
  border-bottom-left-radius: 4px;
}

.queryBuilder-branches .rule::after,
.queryBuilder-branches .ruleGroup .ruleGroup::after {
  top: 50%;
  height: 50%;
  border-width: 0 0 0 1px;
}

.queryBuilder-branches .rule:last-child::after,
.queryBuilder-branches .ruleGroup .ruleGroup:last-child::after {
  display: none;
}

.queryBuilder-branches .ruleGroup .ruleGroup::before {
  top: calc(-0.5rem - 1px);
  height: calc(50% + 0.5rem + 1px);
}

.queryBuilder-branches .ruleGroup .ruleGroup::after {
  height: calc(50% + 1px);
}

.queryBuilder-branches .betweenRules {
  position: relative;
}

.queryBuilder-branches .betweenRules::before {
  content: '';
  width: 2rem;
  left: calc(-0.5rem - 1px);
  border-color: #8081a2;
  border-radius: 0;
  position: absolute;
  top: -0.5rem;
  height: calc(100% + 0.5rem);
  border-width: 0 0 0 1px;
}
